import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Plan, plansPrices } from "@/shared/Plans";
import PlansSelector from "./components/PlansSelector";
import { useDashboardStore } from "@/store/DashboardStore";
import ic_badge_percent from "/assets/ic_badge_percent.svg?url";
import ic_check_circle from "/assets/ic_check_circle.svg?url";
import sh_card_new_l from '/assets/sh_card_new_l.svg?url';
import ic_angle_left from "/assets/ic_angle_left.svg?url";
import back_card from "/assets/back_card.png?url";
import { formatNumber } from "./helpers";
import NavBar from "@/shared/NavBar";
interface PlanCardProps {
  title: string;
  subtitle: string;
  typePlan: string;
  prices: number;
  priceDebAuto?: number;
  label?: ReactNode; 
  features: string[];
  recommended?: boolean;
  setSelectedPlan: () => void;
  plansAutoDebit?: boolean;
  link?: string;
}

export const Plans = ( {showBannerFreeSubscription}: {showBannerFreeSubscription?: boolean} ) => {
  
  const navigate = useNavigate();
  const [selectedPeriod, setSelectedPeriod] = useState("Mensual");
  const [plans] = useState<Plan[]>(plansPrices);
  const { setSelectedPlan } = useDashboardStore((state) => state);
  const [plansAutoDebit, setplansAutoDebit] = useState(true);
  const filteredPlans = plans.filter((plan) => plan.typePlan === selectedPeriod);
  const handleClick = () => {
    navigate("/inicio");
  };

  const handleSelectedPlan = (planId: number) => {
    setSelectedPlan(planId);
    navigate("/pago");
  };

  const handleBack = () => {
    setplansAutoDebit(true);
  }
  //solo si el usuario es la primera vez que se registra , mostrará el banner para elegir plan gratuito 
  const isFirstLogin = localStorage.getItem("isFirstLogin");

  const subscribeAutoDebit = () => {
    setplansAutoDebit(false);
    setSelectedPeriod("Mensual");
  };

  return (
    <>
      <div className="bg-[#F7FBFF] min-h-screen pb-4 px-4">
      {
        isFirstLogin === 'false' && plansAutoDebit && (
        <>
          <div className="bg-[#E8F0FF] border-blue-200 rounded-lg px-6 py-2 mx-2 sm:mx-4 md:mx-12 mt-2 mb-8 flex flex-col md:flex-row items-center justify-between">
            <div className="w-full md:w-2/3 pb-4 md:pr-20 md:pl-9 text-center md:text-left">
              <h2 className="text-lg font-semibold">
                Olvídate de los pagos suscribiéndote al débito automático mensual
              </h2>
              <p className="text-sm mt-2">
                Tu plan se reactivará al día siguiente de haber realizado el pago.
              </p>
              <button
                className="hidden md:block my-5 rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2 bg-white border border-gray-800 hover:bg-gray-800 hover:text-white hover:border-gray-800 transition"
                type="button"
                onClick={subscribeAutoDebit}
              >
                Suscribirme
              </button>
            </div>
            <div className="w-full md:w-1/3 flex items-center justify-evenly mt-2 md:mt-0">
              <button
                className="block md:hidden my-5 rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2 bg-white border border-gray-800 hover:bg-gray-800 hover:text-white hover:border-gray-800 transition"
                type="button"
                onClick={subscribeAutoDebit}
              >
                Suscribirme
              </button>
              <img
                src={sh_card_new_l}
                alt="Ilustración de tarjeta de débito"
                className="w-[75px] h-[75px] md:w-[200px] md:h-[200px]"
              />
            </div>
          </div>
        </>        
        )
      }
      {
        !plansAutoDebit && (
          <div className="flex justify-center">
            <button onClick={handleBack} className="flex justify-center items-center text-md text-[#1B68FF] gap-2 mb-4">
                <img src={ic_angle_left} alt="Volver" className="w-4 h-auto"/>
                Volver a planes
            </button>
          </div>
        )
      }
      <div className="text-center">
        <h1 className="sm:text-[28px] text-[24px] font-bold my-6">
          {plansAutoDebit
            ? "Escoge el plan ideal para ti"
            : "Olvídate de pagar todos los meses con el débito automático"}
        </h1>
        {!plansAutoDebit && (
          <h3>
            Además, obtén 30% de dscto en tu primer mes al suscribirte
          </h3>
        )}
      </div>

      
      {showBannerFreeSubscription && (
        <div className="rounded-lg px-6 py-5 flex gap-4 m-12 bg-[#E0F3FF]">
          <div className="flex-none w-10 h-auto">
            <img src={ic_badge_percent} alt="ContaPro" className="w-16 h-auto" />
          </div>
          <div>
            <p className="text-lg font-bold mb-2">¡Inicia con tu prueba gratis!</p>
            <p className="text-sm mb-3 f-secondary font-normal">
              Tienes <b>7 días</b> de prueba gratis. Para registrar más empresas y recibir toda la
              información escoge uno de nuestros planes.
            </p>
            <button
              className="rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2 bg-white border border-stone-950 hover:border-stone-900"
              onClick={handleClick}
              type="button"
            >
              Empezar prueba gratis
            </button>
          </div>
        </div>
      )}
      
        { plansAutoDebit && <PlansSelector onSelectPeriod={setSelectedPeriod} period={selectedPeriod} />}

        <div className="flex justify-center flex-wrap mt-12 ">
              {filteredPlans.map((plan) => (
                <PlanCard
                  key={plan.id}
                  title={plan.title}
                  typePlan={plan.typePlan}
                  subtitle={plan.subtitle}
                  prices={plan.prices}
                  priceDebAuto={!plansAutoDebit ? plan.priceDebAuto : undefined}
                  label={plan.label}
                  features={plan.features}
                  setSelectedPlan={() => handleSelectedPlan(plan.id)}
                  plansAutoDebit={plansAutoDebit}
                  link={plan.link}
                />
              ))}
              {!plansAutoDebit &&
              <div>
                <p className="text-center text-[14px] mt-3">Tu plan empezará al día siguiente de comprar tu suscripción. Para cancelar tu suscripción, solo escribe al número de soporte de Whatsapp que se te enviará al adquirir un plan. </p>
              </div>
              }
        </div>

      {
        isFirstLogin === 'true' && plansAutoDebit && (
          <>
          <div className="bg-[#E8F0FF] border-blue-200 rounded-lg px-6 py-2 mx-2 sm:mx-4 md:mx-12 mt-8 mb-8 flex flex-col md:flex-row items-center justify-between">
            <div className="w-full md:w-2/3 p-4 md:pr-20 md:pl-9 text-center md:text-left">
              <h2 className="text-lg font-semibold">
                Olvídate de los pagos suscribiéndote al débito automático mensual
              </h2>
              <p className="text-sm mt-2">
                Tu plan se reactivará al día siguiente de haber realizado el pago.
              </p>
              <button
                className="hidden md:block my-5 rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2 bg-white border border-gray-800 hover:bg-gray-800 hover:text-white hover:border-gray-800 transition"
                type="button"
                onClick={subscribeAutoDebit}
              >
                Suscribirme
              </button>
            </div>
            <div className="w-full md:w-1/3 flex items-center justify-evenly mt-2 md:mt-0">
              <button
                className="block md:hidden my-5 rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2 bg-white border border-gray-800 hover:bg-gray-800 hover:text-white hover:border-gray-800 transition"
                type="button"
                onClick={subscribeAutoDebit}
              >
                Suscribirme
              </button>
              <img
                src={sh_card_new_l}
                alt="Ilustración de tarjeta de débito"
                className="w-[75px] h-[75px] md:w-[200px] md:h-[200px]"
              />
            </div>
          </div>
        </>        
        )
      }
    </div>
   </>

  ) 

}

const PlanCard: React.FC<PlanCardProps> = ({
  title,
  subtitle,
  prices,
  features,
  recommended,
  setSelectedPlan,
  plansAutoDebit = true,
  priceDebAuto,
  label,
  link,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRedirectDebtAuto = () => {  
    setIsModalOpen(true);  
  };

  const continuePlan = () => {
    setIsModalOpen(false);
    if (link) {
      window.open(link, '_blank');
    }
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={`w-[360px] border border-gray-300 block pt-11 pb-8 px-6
     bg-white rounded-lg shadow-md m-3 relative ${!plansAutoDebit ? 'animate-fadeIn' : ''}`}>
      {recommended && (
        <div className="absolute top-6 left-6 transform -translate-y-1/2">
          <span className="text-xs bg-[#424446] rounded-[10px] py-1.5 px-2.5 text-white font-bold">Recomendado</span>
        </div>
      )}
      
      <h3 className="text-xl font-bold">{title}</h3>
      <h4 className="text-lg mt-2">
        Registra hasta <b>{subtitle}</b>
      </h4>
      {plansAutoDebit ? (
        <h1 className="text-4xl font-semibold mt-4">S/. {formatNumber(prices)}</h1>
      ) : 
      (
        <div className="flex flex-col">
          <div>
            <h1 className="text-2xl text-[#8782AB] font-semibold mt-4 line-through">S/. {formatNumber(prices)}</h1>
          </div>
          <div className="flex items-end">
            <span className="text-4xl font-bold">S/. {formatNumber(priceDebAuto)}</span>
            <span className="text-base leading-5 text-[#5D6075] ml-2 ">{label}</span>
          </div>
        </div>
      )}
      {plansAutoDebit ? (
      <button
        onClick={setSelectedPlan}
        className="my-5 rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2 bg-white border border-gray-800 hover:bg-gray-800 hover:text-white hover:border-gray-800 transition"
        type="button"
      >
        Elegir plan
      </button>
      ) : (
      <button
        className="my-5 rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2  bg-gray-800 text-white hover:text-gray-300 transition"
        type="button"
        onClick={handleRedirectDebtAuto}
      >
        Elegir plan
      </button>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6">
            <div className="flex justify-end">
              <button onClick={closeModal} className="cursor-pointer">
                <p className="text-lg font-bold " >X</p>
              </button>  
            </div>
            <div className="flex flex-col items-center">
              <div className="w-20 h-20 flex items-center justify-center bg-green-100 rounded-full mb-4">
                <img src={back_card} alt="Imagen trasera de tarjeta" className="w-18" />
              </div>
              <h2 className="text-lg font-semibold text-gray-800 mb-2">¡Aviso importante!</h2>
              <p className="text-gray-500 text-center mb-6">
                Solo si vas a realizar el pago con tarjetas <span className="text-blue-600">BBVA,</span> deberás usar el <span className="font-bold">CVV dinámico</span> para continuar con el pago.
              </p>
              <div className="flex gap-4">
                <button
                  className="rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2  bg-gray-800 text-white hover:text-gray-300 transition"
                  type="button"
                  onClick={continuePlan}
                >
                  Continuar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <hr className="my-4" />
      <div>
        <p className="font-semibold">Que incluye:</p>
        <ul className="list-disc list-inside mt-2">
          {features.map((feature, index) => (
            <li key={index} className="text-gray-700 flex items-center">
              <img src={ic_check_circle} alt="Imagen de check" className="w-4 my-1 mr-4" />
              <span className="text-sm font-medium leading-[1.1] my-1">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const PlansPricesContapro = () => {

  return (
    <>
      <NavBar customCssStyle="border-b" />

      <div className="bg-[#F7FBFF] min-h-screen pb-4 sm:p-6">
        <Plans/>

      </div>
    </>

  );
};

export default PlansPricesContapro;