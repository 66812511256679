import Modal from "@/shared/Modal";
import { ObservedModalProps } from "../models/observedModal";
import ValidateOneInvoiceForm from "./ValidateOneInvoiceForm";

const ValidateOneInvoiceModal = ({
  modalState,
  setModalState,
  closeModalCallback,
  cpes,
}: ObservedModalProps) => {
  return (
    <Modal
      modalState={modalState}
      typeModal="editReport"
      showAlertIcon={false}
      modalWidth="w-3/5"
      onClose={closeModalCallback}
    >
      <div className="w-full py-3">
        <div className="text-left">
          <h2 className="text-xl leading-7 font-medium mb-4">
            Validar comprobante #{Array.isArray(cpes) ? cpes[0]?.number : cpes?.number}
          </h2>
        </div>
        {cpes && <ValidateOneInvoiceForm cpes={Array.isArray(cpes) ? cpes[0] : cpes} setModalState={setModalState} />}
      </div>
    </Modal>
  );
};

export default ValidateOneInvoiceModal;
