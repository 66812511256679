import { useDashboardStore } from "@/store/DashboardStore";
import { useCallback, useState } from "react";
import { StatusValues } from "../models/status-download";
import { DownloadValidatedReport } from "../models/download-validated-report";

export type FormatType = 'xml' | 'jpeg';

export default function useDownload({page, size, validateInvoiceCodesSelected, validateInvoices} : DownloadValidatedReport) {
  const [status, setStatus] = useState<StatusValues>("idle");
  const [alertOpen, setAlertOpen] = useState(false);
  const {
    fetchValidateReportImagesZip
  } = useDashboardStore((state) => state);

  const handleDownload =useCallback(async (
    type: FormatType
  ) => {
    try {
      setStatus("downloading");

      const codes = validateInvoiceCodesSelected.length > 0 ? validateInvoiceCodesSelected : validateInvoices.map(invoice=> invoice.code)

      const zipFile = await fetchValidateReportImagesZip(page,parseInt(size.toString()), codes, type);

      // Convertir el contenido base64 a un buffer de datos
      const zipBuffer = Uint8Array.from(atob(zipFile), c => c.charCodeAt(0));

      // Crear un Blob con los datos del buffer
      const blob = new Blob([zipBuffer], { type: 'application/zip' });

      // Crear una URL para el Blob
      const url = window.URL.createObjectURL(blob);

      // Crear un enlace de descarga
      const a = document.createElement('a');
      a.href = url;
      a.download = type === 'xml' ? 'comprobantes en formato xml.zip' : 'comprobantes en formato jpeg.zip'; // Nombre del archivo descargado
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Liberar la URL del Blob
      window.URL.revokeObjectURL(url);

      setAlertOpen(true);
      setStatus("success");

    } catch (error) {
      console.error('Error al descargar el archivo ZIP:', error);
      setAlertOpen(true);
      setStatus("error");
    }
  }, [page, size, validateInvoiceCodesSelected, validateInvoices]);

  const clearAlert = () => {
    setAlertOpen(false);
    setStatus("idle");
  };

  return {
    status,
    alertOpen,
    handleDownload,
    clearAlert,
  };
}