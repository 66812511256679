import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import FullTable from "@/components/ui/full-table";
import Tabs from "../components/tabs";
import useTableColumns from "./hooks/useTableColumns";
import { useDashboardStore } from "@/store/DashboardStore";
import FilterPeriodSelect from "@/components/shared/period-filter-select";
import usePagination from "@/hooks/use-pagination";
import PaginationSizeSelect from "@/components/shared/pagination-size-select";
import Pagination from "@/components/ui/pagination/pagination";

const tabs = [
  {
      label: "Pagadas",
      value: "S"
  },
  {
      label: "Por pagar",
      value: "N"
  },
]

function Detractions() {
  const [currentTab, setCurrentTab] = useState("S");
  const columns = useTableColumns(currentTab);
  const {
    fetchDetractionsReport,
    detractionsReport: report, 
    valuesFilterSelected: { tax_period },
  } = useDashboardStore((state) => state);

  const { currentPage, pageSize, onChangePageSize, onChangePage } = usePagination();

  useEffect(() => {
    const paid = currentTab;
    fetchDetractionsReport(paid, currentPage, pageSize).catch((error) => {
      console.log("Error:", error);
    });
  }, [currentTab, tax_period, pageSize, currentPage]);

  const handleTabChange = (value: string) => {
    setCurrentTab(value);
    onChangePage(1);
  };

  return (
    <div>
      <Link to="/inicio" className="flex items-center text-neutral-normal gap-1">
        <ChevronLeftIcon className="w-4 h-4" />
        <span className="text-sm f-secondary">Volver a listado de empresas</span>
      </Link>
      <h1 className="font-bold text-2xl mt-6 mb-8">Comprobantes afectos a detracción</h1>
      <div className="flex justify-between items-center">
        <Tabs selectedTab={currentTab} onClick={handleTabChange}  tab={tabs} />
        <div className="flex justify-end gap-4">
          <FilterPeriodSelect />
          <PaginationSizeSelect pageSize={pageSize} onChange={onChangePageSize} />
        </div>
      </div>
      {report && (
        <>
          <FullTable columns={columns} data={report.vouchers} />
          <Pagination currentPage={currentPage} pageCount={report.pagination.total_pages} onChangePage={onChangePage} />
        </>
      )}
    </div>
  );
}

export default Detractions;
