import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { MoveRightIcon } from "lucide-react";

interface ViewVoucherModalProps {
  data: any;
}

export function ViewVoucherModal({ data }: ViewVoucherModalProps) {
  const voucher_unique_code = data?.voucher_unique_code;
  const [, , type_cpe = "-", num_cpe = "-", num_cpe_corr = "-"] = voucher_unique_code.split("-");
//6 20610157921 01 E001 60

const typeDocuments: { [key: string]: string } = {
  "00": "Otros (especificar)",
  "01": "Factura",
  "02": "Recibo por honorarios",
  "03": "Boleta de venta",
  "04": "Liquidación de compra",
  "05": "Boleto de compañía de aviación comercial por el servicio de transporte aéreo de pasajeros",
  "06": "Carta de porte aéreo por el servicio de transporte de carga aérea",
  "07": "Nota de crédito",
  "08": "Nota de débito",
  "09": "Guía de remisión-remitente",
  "10": "Recibo por arrendamiento",
  "11": "Póliza emitida por las bolsas de valores, bolsas de productos o agentes de intermediación por operaciones realizadas en las bolsas de valores o productos o fuera de las mismas, autorizadas por CONASEV",
  "12": "Tique o cinta emitido por máquina registradora",
  "13": "Documento emitido por bancos, instituciones financieras, crediticias y de seguros que se encuentren bajo el control de la Superintendencia de Banca y Seguros",
  "14": "Recibo por servicios públicos de suministro de energía eléctrica, agua, teléfono, télex y telegráficos y otros servicios complementarios que se incluyan en el recibo de servicio público",
  "15": "Boleto emitido por las empresas de transporte público urbano de pasajeros",
  "16": "Boleto de viaje emitido por las empresas de transporte público interprovincial de pasajeros dentro del país",
  "17": "Documento emitido por la Iglesia Católica por el arrendamiento de bienes inmuebles",
  "18": "Documento emitido por las Administradoras Privadas de Fondo de Pensiones que se encuentran bajo la supervisión de la Superintendencia de Administradoras Privadas de Fondos de Pensiones",
  "19": "Boleto o entrada por atracciones y espectáculos públicos",
  "21": "Conocimiento de embarque por el servicio de transporte de carga marítima",
  "22": "Comprobante por Operaciones No Habituales",
  "23": "Pólizas de Adjudicación emitida con ocasión del remate o adjudicación de bienes por venta forzada, por los martilleros o las entidades que rematen o subasten bienes por cuenta de terceros",
  "24": "Certificado de pago de regalías emitidas por PERUPETRO S.A",
  "25": "Documento de Atribución (Ley del Impuesto General a las Ventas e Impuesto Selectivo al Consumo, artículo 19, último párrafo,  R.S. Nº 022-98/SUNAT)",
  "26": "Recibo por el pago de la tarifa por uso de agua superficial con fines agrarios y por el pago de la cuota para la ejecución de una determinada obra o actividad acordada por la asamblea general de la comisión de regantes o Resolución expedida por el jefe de",
  "27": "Seguro Complementario de Trabajo de Riesgo",
  "28": "Tarifa Unificada de uso de aeropuerto",
  "29": "Documentos emitidos por COFOPRI en calidad de oferta de venta de terrenos, los correspondientes a las subastas públicas y a la retribución de los servicios que presta",
  "30": "Documentos emitidos por las empresas que desempeñan el rol adquirente en los sistemas de pago mediante tarjetas de crédito y débito",
  "31": "Guía de remisión-transportista",
  "32": "Documentos emitidos por las empresas recaudadoras de la denominada garantía de red principal a la que hace referencia el numeral 7.6 del artículo 7 de la Ley Nº 27133 - Ley de Promoción del Desarrollo de la Industria del Gas Natural",
  "34": "Documento del operador",
  "35": "Documento del partícipe",
  "36": "Recibo de distribución de gas natural",
  "37": "Documentos que emitan los concesionarios del servicio de revisiones técnicas vehiculares, por la prestación de dicho servicio",
  "50": "Declaración Única de Aduanas - Importación definitiva",
  "52": "Despacho Simplificado - Importación Simplificada",
  "53": "Declaración de Mensajería o Courier",
  "54": "Liquidación de cobranza",
  "55": "BVME para transporte ferroviario de pasajeros",
  "87": "Nota de Crédito Especial",
  "88": "Nota de Débito Especial",
  "91": "Comprobante de No Domiciliado",
  "96": "Exceso de crédito fiscal por retiro de bienes",
  "97": "Nota de Crédito - No domiciliado",
  "98": "Nota de Débito - No domiciliado",
  "99": "Otros - Consolidado de boletas de venta"
};


const getDocumentTypeName = (type: string | number): string => {
  const description = typeDocuments[type] || "No especificado";
  return truncateText(description, 75)
};

function truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + "...";
}

  const fields = [
    { label: "Número de constancia", value: data?.certificate_number || "-"},
    { label: "Usuario SOL", value: data?.sol_user || "-" },
    { label: "N° de cuenta de detracciones", value: data?.account_number || "-" },
    { label: "Tipo de cuenta", value: data?.account_type_name || "-" },//blanco
    { label: "RUC del proveedor", value: data?.customer_identity_number },
    { label: "Nombre/ Razón Social del Proveedor", value: data?.supplier_business_name },
    { label: "Tipo de Operación", value: data?.operation_name || "-" },
    { 
      label: "Tipo de bien o servicio", 
      value: `${data?.good_or_service_code || "-"} ${data?.good_or_service_name || ""}`
    },    
    { label: "Monto del depósito", value: `S/ ${data?.deposit_amount || 0}` },    
    { label: "Fecha y hora de pago", value: data?.payment_datetime || "-"},
    { label: "Periodo tributario", value: data?.tax_period_payment || "-"},
    { 
      label: "Tipo de comprobante", 
      value: `${type_cpe} - ${getDocumentTypeName(type_cpe)}`
    },
    { 
      label: "Número de comprobante", 
      value: `${num_cpe || "-"} - ${num_cpe_corr || "-"}`
    },
    { label: "Número de operación", value: data?.operation_number || "-" },
    { label: "Número de pago de detracciones", value: data?.detraction_payment || "-" },//blanco
  ];

  return (
    <Dialog>
      <DialogTrigger className="flex gap-2 items-center mt-2">
        <span className="text-blue-normal cursor-pointer font-bold">Ver Comprobante </span>
        <MoveRightIcon className="w-4 h-4" />
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Constancia de depósito</DialogTitle>
        <DialogDescription className="text-center"></DialogDescription>
        <div className="text-left text-sm">
          <table className="w-full border-collapse">
            <tbody>
              {fields.map((field, index) => (
                <tr key={index}>
                  <td className="font-semibold pr-4 py-1 leading-none">{field.label}:</td>
                  <td className="py-1">{field.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </DialogContent>
    </Dialog>
  );
  
}

