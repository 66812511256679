import Loader from "../Loader";
import RegisterAccountantForm from "./RegisterAccountantForm";
import "./RegisterAccountant.css";
import logoContapro from "/assets/logo-contapro-credicorp.png?url";
import CardImg4 from "/assets/register_accountant_4.png?url";
import CardImg2 from "/assets/register_accountant_2.png?url";
import CardImg3 from "/assets/register_accountant_3.png?url";
import sideImg2 from "/assets/sideImg2.png?url";

import Card from '../shared/CardInfo.tsx';
import CommentCarousel from "@/dashboard/CommentCarousel.tsx";
import ic_check from "/assets/ic_check.svg?url";

function RegisterAccountant() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <main className="min-h-screen w-full">
            <Loader/>
            <div className="xl:min-h-screen xl:flex bg-white relative">
                <div className="w-full bg-white xl:w-6/12 flex flex-col xl:bg-[#E0E9FF] relative">
                    <div className="xl:max-w-[488px] mx-auto w-full py-5 px-5 mb-8 xl:px-0 border-b xl:border-none">
                        <img src={ logoContapro } alt="Contapro"/>
                    </div>
                    <div className="hidden xl:flex items-center justify-center">
                        <div className="">
                            <div className="w-4/5 ml-16">
                                <p className="font-medium text-2xl mb-5">
                                    Ahorra <span className="font-bold text-[#1B68FF]">50% de tiempo</span> en la <br />
                                    declaración de IGV de tus clientes
                                </p>
                                <p className="flex text-base mb-5">
                                    <img src={ic_check} alt="check" className="mr-1" />
                                    Obtén todos los comprobantes de tus clientes con detalle y XML.
                                </p>
                                <p className="flex text-base mb-5">
                                    <img src={ic_check} alt="check" className="mr-1" />
                                    Contapro separa gastos ajenos a la empresa por ti.
                                </p>
                                <p className="flex text-base mb-5">
                                    <img src={ic_check} alt="check" className="mr-1" />
                                    Obtén el análisis de detracciones, retenciones, y percepciones.
                                </p>
                            </div>
                            <div className="relative overflow-hidden mt-16">
                                <img 
                                    src={sideImg2} 
                                    alt="Dashboard Contapro" 
                                    className="w-full object-cover rounded-lg transform translate-y-4 scale-95"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <RegisterAccountantForm />
            </div>
            
            <hr className="mt-4 xl:mt-0"/>
            <div className="container mx-auto px-4 mt-6 mb-4 lg:my-16 max-w-[1062px]">
                <div className="mb-14 md:flex md:items-center md:justify-between">
                    <h2 className="font-bold text-xl md:text-3xl text-center">
                        Deja de perder tiempo en la <br/>
                        <span className="font-bold text-[#1B68FF]">declaración de tus clientes</span>
                    </h2>
                    <div className="sm:mt-4 md:mt-0">
                        <button
                            onClick={scrollToTop}
                            id="create-account"
                            type="submit"
                            className="hidden md:block primary-button-3 py-2 px-4 w-60 h-14"
                            >
                            Inicia tu prueba gratis
                        </button>
                    </div>
                </div>

                <div className="lg:flex gap-4">
                    <Card className="border-none w-full bg-[#EDEFF4] lg-mb-0 rounded-3xl my-4">
                        <div className="flex flex-col lg:flex-row gap-4 justify-between items-center">
                        <div className="lg:w-3/5 lg:px-12">
                            <p className="font-semibold text-lg lg:text-[20px] mb-2">
                            Los indicadores que a tus clientes le interesan
                            </p>
                            <p className="text-base f-secondary lg:text-lg mb-5 text-justify">
                            Cálculos de IGV, detracciones, percepciones, retenciones, y más en el reporte de tus clientes.
                            </p>
                        </div>
                        <div className="lg:w-2/5">
                            <img className="w-full h-auto rounded-2xl" src={CardImg4} alt="Indicadores imagen" />
                        </div>
                        </div>
                    </Card>
                </div>
            
                <div className="lg:flex gap-4">
                    <Card className="border-none w-full lg:w-1/2 bg-[#EDEFF4] lg-mb-0 rounded-3xl my-4">
                        <div className="register-accountant-card__2--img text-center mx-auto mb-2">
                            <img className="inline-block w-full h-auto" src={ CardImg2 } alt="Comprobantes imagen"/>
                        </div>
                        <div>
                            <p className="font-semibold text-lg lg:text-[20px]">Comprobantes con ítems</p>
                            <p className="text-base f-secondary lg:text-lg">Extraemos los comprobantes de SIRE y SUNAT
                                completas.</p>
                        </div>
                    </Card>
                    <Card className="border-none w-full lg:w-1/2 bg-[#EDEFF4] rounded-3xl my-4">
                        <div className="register-accountant-card__2--img mx-auto mb-2">
                            <img className="w-full h-auto inline-block" src={ CardImg3 }
                                 alt="Analisis de comprobantes imagen"/></div>
                        <div>
                            <p className="font-semibold text-lg lg:text-[20px]">Análisis y observaciones</p>
                            <p className="text-base f-secondary lg:text-lg">Identificamos gastos ajenos a la empresa por
                                ti.</p>
                        </div>
                    </Card>
                </div>
                <div className="md:mt-0 my-12">
                    <button
                        onClick={scrollToTop}
                        id="create-account"
                        type="submit"
                        className="block md:hidden primary-button-3 py-2 px-4 h-14 w-full"
                        >
                        Inicia tu prueba gratis
                    </button>
                </div>
            </div>
            
            <CommentCarousel />

            <div className="bg-[#15161C] px-4">
                <div className="container mx-auto max-w-[1062px] py-4 lg:py-16 text-center">
                    <h2 className="font-bold text-xl lg:text-[32px] text-white max-w-[846px] mx-auto mb-4 leading-tight">
                        <span className="bg-blue-600 font-bold text-xl lg:text-[32px] rounded-md px-1">Olvídate</span> de
                        las tareas manuales de la declaración con Contapro</h2>
                    <p className="font-normal text-base lg:text-lg text-white">Elige el plan que se adapte mejor a ti, y
                        regístrate.</p>

                    <button onClick={ scrollToTop } type="button"
                            className="rounded-[32px] py-2 px-6 mt-4 bg-white text-[#15161C] f-secondary font-semibold text-base">Regístrate
                    </button>
                    <hr className="hidden mt-[50px] lg:block bg-[#5D6075]"/>

                </div>
            </div>
  

            
            


        </main>
    );
}

export default RegisterAccountant;
