import { StatusValues } from "../models/status-download";
import successIcon from "@/assets/sp_documents_d.svg";
import errorIcon from "@/assets/documents_error.svg";

export default function useDownloadStatusMessage(status: StatusValues) {
  switch (status) {
    case "success":
      return {
        title: "Descarga completada",
        description: "Los comprobantes fueron descargados exitosamente.",
        icon: successIcon,
      };

    case "error":
      return {
        title: "No se completó la descarga",
        description: "Parece que hubo un problema.",
        icon: errorIcon,
      };

    default:
      return {
        title: "No se tiene información de la descarga",
        description: "No se tiene información de la descarga",
      };
  }
}
