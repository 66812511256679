import { SearchIcon } from "lucide-react";
import CreateBusinessButton from "./CreateBusinessButton";

interface HeaderHomeProps {
  name: string;
  setSearching: (searching: string) => void;
  businessCardDisabled: boolean;
}

export default function HeaderHome({ name, setSearching, businessCardDisabled }: HeaderHomeProps) {
  return (
    <div className="mb-6">
      <div className="md:flex justify-between mb-6">
        <div className="mb-4 md:mb-0">
          <h2 className="home__title text-3xl font-bold mb-3">
            Hola de nuevo,{" "}
            <span className={name ? "text-blue-normal font-bold" : ""}>{name || "..."}</span>
          </h2>
          <h3 className="home__subtitle f-secondary">Gestiona la información de tus empresas</h3>
        </div>

        <div className="hidden md:block">
          <CreateBusinessButton disabled={businessCardDisabled}/>
        </div>
      </div>
      <form className="w-[288px]">
        <div className="relative w-100">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <SearchIcon className="w-4 h-4 text-gray-400" />
          </div>
          {/* TODO: USE INPUT COMPONENT INSTEAD  */}
          <input
            type="search"
            id="default-search"
            className="block w-full py-2.5 px-3 ps-10 text-sm text-gray-900 border border-[#C7CADD] rounded-lg bg-white focus:ring-gray-700 focus:border-gray-700 outline-none"
            placeholder="Buscar empresa"
            autoComplete="off"
            onChange={(e) => {
              setSearching(e.target.value);
            }}
          />
        </div>
      </form>
    </div>
  );
}


