export function calculateDaysPassed(daysPassed: string) {
    const daysPassedToDate = new Date(daysPassed);
    const actualDate = new Date();

    const differenceMs = actualDate.getTime() - daysPassedToDate.getTime();
    return Math.floor(differenceMs / (1000 * 60 * 60 * 24));
}

export function isSubscriptionExpire(dateString: string | number): boolean {
    const expiration = new Date(dateString);
    const today = new Date();
  
    const timeDiff = expiration.setHours(0,0,0,0) - today.setHours(0,0,0,0);
    const daysLeft = timeDiff / (1000 * 3600 * 24);
    
    return daysLeft <= 5 && daysLeft > 0; // Retorna true si la suscripción expira en 5 días o menos
  };

