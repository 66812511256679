import { useState } from 'react';
import './CommentCarousel.css';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import Profile1 from "/assets/profile1.png?url";
import Profile2 from "/assets/profile2.png?url";
import Profile3 from "/assets/profile3.png?url";

const CommentCarousel = () => {
  const comments = [
    { image: Profile1, user: 'Contador independiente', profile: 'Cinthia Candela', text: '"Contapro me ahorra 4 horas en la gestión de mis  clientes."' },
    { image: Profile2, user: 'Contador Colegiado', profile: 'Eder T.', text: '"Contapro te da todos los archivos que necesitas para tus clientes."' },
    { image: Profile3, user: 'Contador independiente', profile: 'Cliente Contapro', text: '"Recomiendo Contapro a todos mis colegas porque ya no tengo que recopilar comprobantes."' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 2;

  const currentComments = comments.slice(currentIndex, currentIndex + itemsPerPage + 1);

  const nextPage = () => {
    if ((currentIndex + 1) * itemsPerPage < comments.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevPage = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="flex flex-col items-center mx-4 sm:mx-8 md:mx-14 lg:mx-24 my-5 mb-32">
      <div className="w-full flex justify-between">
        <div className="text-container">
          <h2 className="font-bold text-xl md:text-3xl text-center mb-4 sm:text-xl md:text-left">
            Únete a
            <span className="font-bold text-[#1B68FF]"> más de 100 contadores </span>
            y estudios contables <br />que automatizan y ahorran tiempo.
          </h2>
        </div>
       <div className="carousel-buttons md:flex hidden">
          <button
            onClick={prevPage}
            disabled={currentIndex === 0}
            className="w-12 h-12 cursor-pointer border border-transparent rounded-full bg-transparent flex justify-center items-center text-gray-500 mx-1 hover:bg-[#3461FF] hover:text-white disabled:opacity-50"
          >
            <ArrowLeft />
          </button>
          <button
            onClick={nextPage}
            disabled={(currentIndex + 1) * itemsPerPage >= comments.length}
            className="w-12 h-12 cursor-pointer border border-transparent rounded-full bg-transparent flex justify-center items-center text-gray-500 mx-1 hover:bg-[#3461FF] hover:text-white disabled:opacity-50"
          >
            <ArrowRight />
          </button>
        </div>

      </div>

      <div className="sm:flex sm:overflow-hidden w-full mt-8">
        {currentComments.map((comment, index) => (
          <div key={index} className={`comment-card ${index === 0 ? 'active' : ''}`}>
            <div className="flex items-center	">
              <img src={comment.image} alt={comment.user} />
              <div>
                <p>{comment.user}</p>
                <h5 className='mt-2'>{comment.profile}</h5>
              </div>
            </div>
            
            <p className='text-base mt-4'>{comment.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentCarousel;