import { CONTAPRO_ANALYTICS } from '../shared/analytics.tsx';

export const formatNumber = (num = 0) => {
    const options: Intl.NumberFormatOptions = {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };
    return new Intl.NumberFormat("en-US", options).format(Number(num.toFixed(2)));
};
export const descendingComparator = (a: string, b: string) => {
    if (a > b) {
        return -1;
    }

    if (a < b) {
        return 1;
    }

    return 0;
};
export const COLOR_FACTURAS = "#00A0B6";
export const COLOR_BOLETAS = "#6FDDF6";
export const COLOR_3 = "#5754FF";
export const COLOR_NOTAS_CREDITO = "#3D77FF";
export const COLOR_NOTAS_DEBITO = "#BEF22C";

export const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
}

export 	const getInitials = (businessName: string) => {
    const words = businessName?.split(' ');

    if (words.length < 2) {
        return words[0].charAt(0).toUpperCase();
    }
    const firstInitial = words[0].charAt(0).toUpperCase();
    const secondInitial = words[1].charAt(0).toUpperCase();

    return firstInitial + secondInitial;
}

export const menuListItems: MenuItem[] = [
    {
        title: "Inicio",
        icon: "house",
        tagAnalytics: CONTAPRO_ANALYTICS.HOME.MENU_HOME.ACTION,
        path: "/dashboard",
        subpaths: ["/dashboard"],
    },
    {
        title: "Observaciones",
        icon: "denied_document_black",
        tagAnalytics: '',
        path: "observaciones",
    },
    {
        title: "Comprobantes validados",
        icon: "document_approved",
        tagAnalytics: '',
        path: "facturas-validadas",
    },
    {
        title: "Percepciones",
        icon: "document_plus_grey",
        tagAnalytics: '',
        path: "percepciones",
    },
    {
        title: "Retenciones",
        icon: "document_less_grey",
        tagAnalytics: '',
        path: "retenciones",
    },
    {
        title: "Detracciones",
        icon: "document_card",
        tagAnalytics: '',
        path: "detracciones",
    },
    {
        title: "Informe PDT",
        icon: "document_grey",
        tagAnalytics: '',
        path: "informe-pdt",
    },
    {
        title: "Editar credenciales",
        icon: "edit",
        tagAnalytics: '',
        path: "editar-credenciales",
    },
    {
        title: "Subir archivos",
        icon: "upload",
        tagAnalytics: '',
        path: "subir-archivos",
    }
];

export function getDaysUntilEndDate(date: string | undefined): number {
    if (!date) return 0;
    
    const endDate = new Date(date);
    const today = new Date();
    
    const timeDiff = endDate.setHours(0,0,0,0) - today.setHours(0,0,0,0);
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    
    return daysDiff;
}

