import './PlansSelector.css';
interface PlansSelectorProps {
    period: string;
    onSelectPeriod: (period: string) => void;
    disabled?: boolean
}

const PlansSelector: React.FC<PlansSelectorProps> = ({ onSelectPeriod, period, disabled }) => {

    const handleSelect = (option: string) => {
        if (disabled) return;
        onSelectPeriod(option);
    };

    return (
        <div className="subscription-selector font-primary-font">
            {['Mensual', 'Trimestral', 'Anual'].map(option => (
                <div
                    key={option}
                    className={`option ${period === option ? 'active' : ''} ${disabled && period !== option ? 'disabled' : ''}`}
                    onClick={() => handleSelect(option)}
                >
                    {option}
                </div>
            ))}
        </div>
    );
};

export default PlansSelector;
