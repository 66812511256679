import Modal from "@/shared/Modal";
import { useEffect, useState } from "react";
import angleLeftIcon from "/assets/ic_angle_left.svg?url";
import ValidateOneInvoiceForm from "./ValidateOneInvoiceForm";
import { ObservedModalProps } from "../models/observedModal";
import PendingObservedItems from "./PendingObservedItems";

const ShowItemsModal = ({
  modalState,
  setModalState,
  closeModalCallback,
  cpes,
}: ObservedModalProps) => {
  const [isButtonValidateClicked, setIsButtonValidateClicked] = useState(false);
  const onAprobeReport = () => {
    setIsButtonValidateClicked(true);
  };

  useEffect(() => {
    setIsButtonValidateClicked(false);
  }, [closeModalCallback]);

  return (
    <Modal
      modalState={modalState}
      typeModal="editReport"
      modalWidth="w-5/6"
      showAlertIcon={false}
      onClose={closeModalCallback}
    >
      <div className="w-full">
        {!isButtonValidateClicked ? (
          <PendingObservedItems cpes={Array.isArray(cpes) ? cpes[0] : cpes} onAprobeReport={onAprobeReport} />
        ) : (
          <div>
            <div
              className="flex gap-2 mb-4 cursor-pointer"
              onClick={() => setIsButtonValidateClicked(false)}
            >
              <img className="inline-block" src={angleLeftIcon} alt="Volver" />
              <p className="text-sm font-medium text-[#5D6075]">Volver</p>
            </div>

            <div className="text-left">
              <h2 className="text-xl leading-7 font-medium mb-4">
                Validar comprobante #{Array.isArray(cpes) ? cpes[0]?.number : cpes?.number}
              </h2>
            </div>
            {cpes && (
              <ValidateOneInvoiceForm
                cpes={Array.isArray(cpes) ? cpes[0] : cpes}
                setModalState={setModalState}
                setIsButtonValidateClicked={setIsButtonValidateClicked}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ShowItemsModal;
