import PaginationSizeSelect from "@/components/shared/pagination-size-select";
import FullTable, { TableColumn } from "@/components/ui/full-table";
import Pagination from "@/components/ui/pagination/pagination";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import ValidateAllSelectInvoiceModal from "../components/ValidatedAllSelectInvoiceModal";
import { useState } from "react";
import { ModalState } from "@/shared/Modal";
import { useDashboardStore } from "@/store/DashboardStore";

const RejectedObserved = ({
  reportsRejected,
  pagination: { currentPage, pageSize, onChangePage, onChangePageSize },
  tableColumns: { columns, selectedRows, setSelectedRows, handleSelectAllChange },
}: {
  reportsRejected: ReportsObserved;
  pagination: {
    currentPage: number;
    pageSize: number;
    onChangePage: (page: number) => void;
    onChangePageSize: (pageSize: number) => void;
  };
  tableColumns: {
    columns: TableColumn[];
    selectedRows: Cpes[];
    setSelectedRows: (rows: Cpes[]) => void;
    handleSelectAllChange: (value: boolean) => void;
  };
}) => {
  const [isModalOpenMasiveValidated, setModalOpenMasiveValidated] = useState<ModalState>(
    ModalState.CLOSE,
  );
  const { fetchDashboardReportsRejected } = useDashboardStore((state) => state);

  const handleClickCloseModalOpenMasiveValidated = () => {
    if (isModalOpenMasiveValidated === ModalState.DONE) {
      setSelectedRows([]);
      fetchDashboardReportsRejected(currentPage, pageSize);
    }
    setModalOpenMasiveValidated(ModalState.CLOSE);
  };

  return reportsRejected?.cpes?.length > 0 ? (
    <>
      <div className="flex flex-col sm:flex-row items-center">
        <div className="flex sm:order-1 w-full items-center justify-between md:justify-end gap-1 md:gap-4 my-4">
          <div className="w-1/2 sm:w-fit">
            <PaginationSizeSelect
              className="w-full"
              pageSize={pageSize}
              onChange={onChangePageSize}
            />
          </div>
          {selectedRows.length > 0 && (
            <>
              <span className="text-neutral-dark inline w-1/2 text-center sm:hidden">
                ({selectedRows.length}) seleccionados
              </span>
              {selectedRows.length > 1 && (
                <>
                  <Button
                    variant={"success"}
                    className="hidden sm:block"
                    onClick={() => setModalOpenMasiveValidated(ModalState.OPEN)}
                  >
                    Validar ({selectedRows.length})
                  </Button>
                  <ValidateAllSelectInvoiceModal
                    modalState={isModalOpenMasiveValidated}
                    setModalState={setModalOpenMasiveValidated}
                    closeModalCallback={() => handleClickCloseModalOpenMasiveValidated()}
                    cpes={selectedRows}
                  />
                </>
              )}
              <Separator orientation="vertical" className="h-5 hidden sm:block" />
              <span
                className="text-center text-blue-normal hover:underline cursor-pointer text-sm hidden sm:inline"
                onClick={() => handleSelectAllChange(false)}
              >
                Deseleccionar
              </span>
            </>
          )}
        </div>
        {selectedRows.length > 0 && (
          <>
            <div className="w-full flex items-center justify-between sm:justify-start text-sm gap-1 my-4">
              <span className="text-neutral-dark hidden sm:inline">
                {selectedRows.length} comprobantes seleccionados
              </span>
              <Separator orientation="vertical" className="h-5 hidden sm:block" />
              <span
                className="w-1/2 text-center sm:w-fit sm:text-center text-blue-normal hover:underline cursor-pointer"
                onClick={() => handleSelectAllChange(true)}
              >
                Seleccionar todo ({reportsRejected?.cpes?.length})
              </span>
              <Separator orientation="vertical" className="h-5 sm:hidden" />
              <span
                className="w-1/2 text-center text-blue-normal hover:underline cursor-pointer text-sm sm:hidden"
                onClick={() => handleSelectAllChange(false)}
              >
                Deseleccionar
              </span>
            </div>
            {selectedRows.length > 1 && (
              <div className="w-full flex text-sm gap-2 my-4 sm:hidden">
                <Button
                  variant={"success"}
                  className="w-1/2"
                  onClick={() => setModalOpenMasiveValidated(ModalState.OPEN)}
                >
                  Validar ({selectedRows.length})
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <FullTable columns={columns} data={reportsRejected.cpes} />
      <Pagination
        currentPage={currentPage}
        onChangePage={onChangePage}
        pageCount={reportsRejected.data.pagination.total_pages}
      />
    </>
  ) : (
    <p className="my-5 text-sm font-normal text-gray-500">
      No hay data para el periodo seleccionado
    </p>
  );
};

export default RejectedObserved;
