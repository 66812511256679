import { Link, useLocation } from 'react-router-dom';
import { useState } from "react";
import ic_arrow from "/assets/ic_arrow_left.svg?url";
import ic_sparks from "/assets/ic_sparks-white.svg?url";
import ic_check from "/assets/ic_filed-check-icon.svg?url";
import "./PlansContapro.css";
import { eventGa4Service } from '../ga4.service.tsx';
import { CONTAPRO_ANALYTICS } from '../shared/analytics.tsx';
import { formatNumber } from './helpers.ts';

interface PlanCardInfo {
    type: string;
    businessNumber: number;
    price: number;
    linkToWhatsapp: string;
    characteristics: string[];
}

interface PlansContapro {
    mensual: PlanCardInfo[];
    anual: PlanCardInfo[];
}


const plans: PlansContapro = {
    mensual: [
        {
            type: "Independientes",
            businessNumber: 5,
            price: 59,
            linkToWhatsapp: "https://wa.link/jnhpyd",
            characteristics: [
                'Visualización de comprobantes con ítems en PDF y XML',
                'Formatos RCE y RVIE',
                'Reporte de indicadores',
                'Percepciones, retenciones, y detracciones.'
            ]
        },
        {
            type: "Pequeño negocio",
            businessNumber: 15,
            price: 118,
            linkToWhatsapp: "https://wa.link/sej5nl",
            characteristics: [
                'Visualización de comprobantes con ítems en PDF y XML',
                'Formatos RCE y RVIE',
                'Reporte de indicadores',
                'Percepciones, retenciones, y detracciones.'
            ]
        },
        {
            type: "Negocio emergente",
            businessNumber: 50,
            price: 177,
            linkToWhatsapp: "https://wa.link/8yjx6w",
            characteristics: [
                'Visualización de comprobantes con ítems en PDF y XML',
                'Formatos RCE y RVIE',
                'Reporte de indicadores',
                'Percepciones, retenciones, y detracciones.'
            ]
        },
        {
            type: "Estudio contable",
            businessNumber: 50,
            price: 0,
            linkToWhatsapp: "https://wa.me/51995144727?text=%C2%A1Hola!%20%F0%9F%91%8B%20Me%20interesa%20el%20plan%20Estudio%20Contable%20de%20Contapro.",
            characteristics: [
                'Visualización de comprobantes con ítems en PDF y XML',
                'Formatos RCE y RVIE',
                'Reporte de indicadores',
                'Percepciones, retenciones, y detracciones.'
            ]
        }
    ],
    anual: [
        {
            type: "Independientes",
            businessNumber: 5,
            price: 49.17,
            linkToWhatsapp: "https://wa.link/jnhpyd",
            characteristics: [
                'Visualización de comprobantes con ítems en PDF y XML',
                'Formatos RCE y RVIE',
                'Reporte de indicadores',
                'Percepciones, retenciones, y detracciones.'
            ]
        },
        {
            type: "Pequeño negocio",
            businessNumber: 15,
            price: 98.33,
            linkToWhatsapp: "https://wa.link/sej5nl",
            characteristics: [
                'Visualización de comprobantes con ítems en PDF y XML',
                'Formatos RCE y RVIE',
                'Reporte de indicadores',
                'Percepciones, retenciones, y detracciones.'
            ]
        },
        {
            type: "Negocio emergente",
            businessNumber: 50,
            price: 147.50,
            linkToWhatsapp: "https://wa.link/8yjx6w",
            characteristics: [
                'Visualización de comprobantes con ítems en PDF y XML',
                'Formatos RCE y RVIE',
                'Reporte de indicadores',
                'Percepciones, retenciones, y detracciones.'
            ]
        },
        {
            type: "Estudio contable",
            businessNumber: 50,
            price: 0,
            linkToWhatsapp: "https://wa.me/51995144727?text=%C2%A1Hola!%20%F0%9F%91%8B%20Me%20interesa%20el%20plan%20Estudio%20Contable%20de%20Contapro.",
            characteristics: [
                'Visualización de comprobantes con ítems en PDF y XML',
                'Formatos RCE y RVIE',
                'Reporte de indicadores',
                'Percepciones, retenciones, y detracciones.'
            ]
        }
    ]
}

const PlanCard = ({ planCardInfo, planType, indexCard, subscription }: { planCardInfo: PlanCardInfo; planType: string; indexCard: number; subscription: 'free' | 'expired_plan'; }) => {
    const tagAnalyticsFreeMonthlyPlan = (index: number) => {
        switch (index) {
            case 1:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.FREE.MONTHLY_1.ACTION
                });
                break;
            case 2:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.FREE.MONTHLY_2.ACTION
                });
                break;
            case 3:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.FREE.MONTHLY_3.ACTION
                });
                break;
            case 4:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.FREE.MONTHLY_4.ACTION
                });
                break;
            default:
                break;
        }
    }

    const tagAnalyticsFreeAnnualPlan = (index: number) => {
        switch (index) {
            case 1:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.FREE.ANNUAL_1.ACTION
                });
                break;
            case 2:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.FREE.ANNUAL_2.ACTION
                });
                break;
            case 3:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.FREE.ANNUAL_3.ACTION
                });
                break;
            case 4:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.FREE.ANNUAL_4.ACTION
                });
                break;
            default:
                break;
        }
    }

    const tagAnalyticsSelfRegistrationMonthly = (index: number) => {
        switch (index) {
            case 1:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.SELF_REGISTRATION.MONTHLY_1.ACTION
                });
                break;
            case 2:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.SELF_REGISTRATION.MONTHLY_2.ACTION
                });
                break;
            case 3:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.SELF_REGISTRATION.MONTHLY_3.ACTION
                });
                break;
            case 4:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.SELF_REGISTRATION.MONTHLY_4.ACTION
                });
                break;
            default:
                break;
        }
    }

    const tagAnalyticsSelfRegistrationAnnualPlan = (index: number) => {
        switch (index) {
            case 1:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.SELF_REGISTRATION.ANNUAL_1.ACTION
                });
                break;
            case 2:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.SELF_REGISTRATION.ANNUAL_2.ACTION
                });
                break;
            case 3:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.SELF_REGISTRATION.ANNUAL_3.ACTION
                });
                break;
            case 4:
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.PLANS.SELF_REGISTRATION.ANNUAL_4.ACTION
                });
                break;
            default:
                break;
        }
    }

    const handleChoosePlanButton = () => {
        if (subscription === 'free' && planType === 'mensual') {
            tagAnalyticsFreeMonthlyPlan(indexCard);
        }

        if (subscription === 'free' && planType === 'annual') {
            tagAnalyticsFreeAnnualPlan(indexCard);
        }

        if (subscription === 'expired_plan' && planType === 'mensual') {
            tagAnalyticsSelfRegistrationMonthly(indexCard);
        }

        if (subscription === 'expired_plan' && planType === 'annual') {
            tagAnalyticsSelfRegistrationAnnualPlan(indexCard);
        }
    }

    return (
        <div className="card-plans h-full mb-6 sm:mb-0 flex-[1_1_100%]">
            <h2 className="font-bold text-2xl text-[#170F49]">{planCardInfo.type}</h2>
            <p className="text-lg font-normal">
                {
                    planCardInfo.type.includes("Estudio contable")
                    ? `A partir de ${planCardInfo.businessNumber} empresas`
                    : `Máximo ${planCardInfo.businessNumber} empresas registradas.`
                }
            </p>
            {
                planCardInfo.type.includes("Estudio contable")
                ?   <p className="text-[32px] text-[#170F49] font-bold my-4">Cotiza</p>
                :   <p className=" my-4">
                        <span className="text-[54px] font-bold text-[#170F49]">s/ {formatNumber(planCardInfo.price)}</span>
                        <span className="text-[20px] font-medium text-[#6F6C90]"> mensual</span>
                    </p>
            }
            <div>
                <a
                    className="inline-block bg-primary-blue px-8 py-5 text-white text-[20px] rounded-[96px]"
                    href={planCardInfo.linkToWhatsapp}
                    target="_blank"
                    onClick={() => handleChoosePlanButton()}
                >Elegir plan</a>
            </div>
            <hr className="my-5"/>
            <p className="text-lg font-semibold text-[#170F49]">Qué incluye</p>
            <ul>
                {planCardInfo.characteristics.map((characteristic, index) => (
                    <li key={'list-characteristics-plan-' + index} className="flex gap-4 text-lg font-normal text-[#170F49] mt-4">
                        <img src={ic_check} alt="Dot" className="w-[26px] h-auto flex-none"/>
                        {characteristic}
                    </li>
                ))}
            </ul>
        </div>
    );
};

function PlansContapro() {
    const location = useLocation();
    const [switchValue, setSwitchValue] = useState(false);

    const handleSwitchChange = () => {
        setSwitchValue(!switchValue);
    };

    return (
        <div className="bg-[#F7FBFF] min-h-screen p-4 md:py-10 md:p-6">
            <div className="text-center">
                <Link to="/inicio" className="flex justify-center gap-2 text-lg mb-4">
                    <img src={ic_arrow} alt="Volver" className="w-6 h-auto"/>
                    Volver al inicio
                </Link>
                <h1 className="text-[28px] font-bold">Escoge el plan ideal para ti</h1>
                <p className="text-lg">Precios sin IGV</p>
            </div>

            <div className="text-center mt-8">
                <div className="flex gap-3 items-center justify-center">
                    <p className={switchValue ? "text-lg font-normal" : "text-lg font-bold"}>Mensual</p>
                    <label className="inline-flex items-center cursor-pointer">
                        <input
                            type="checkbox"
                            value=""
                            className="sr-only peer"
                            checked={switchValue}
                            onChange={handleSwitchChange}
                        />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#1B68FF]"></div>
                    </label>
                    <div className="flex gap-3">
                        <p className={switchValue ? "text-lg font-bold" : "text-lg font-normal"}>Anual</p>
                        <div className="bg-black inline-flex items-center gap-2 rounded-lg px-3 py-[2px]">
                            <span className="text-white flex-none text-xs">2 meses gratis</span>
                            <img src={ic_sparks} alt="Sparks" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="sm:grid grid-cols-2 gap-6 xl:flex md:justify-between md:gap-3 mt-5">
                {
                    plans[switchValue ? "anual" : "mensual"]
                        .map((plan, index) =>
                            <PlanCard
                                key={'plan-' + index}
                                planCardInfo={plan}
                                planType={switchValue ? "annual" : "mensual"}
                                indexCard={index + 1}
                                subscription={location.state?.planType || 'free'}
                            />)
                }
            </div>
        </div>
    );
}

export default PlansContapro;
