import { ReactNode } from "react";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "./table";

export interface TableColumn {
  key: string;
  label: string | (() => ReactNode);
  minWidth?: string;
  textAlign?: string;
  render?: (row: any) => ReactNode
}

interface TableProps {
  data: any[];
  columns: TableColumn[];
}

export default function FullTable({ data, columns }: TableProps) {
  return (
  <div className="grid">
    <Table className="table-auto w-full border-collapse">
      <TableHeader className="sticky top-0 z-5 bg-white">
        <TableRow>
          {columns.map((column) => (
            <TableHead
              key={column.key}
              minWidth={column.minWidth}
              textAlign={column.textAlign}
              className="px-2 py-1 text-left text-[12px]"
            >
              {typeof column.label === "function"
                ? column.label()
                : column.label}
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      {data.length ? (
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex} className="hover:bg-gray-100">
              {columns.map((column) => (
                <TableCell
                  key={`${rowIndex}-${column.key}`}
                  className="break-words text-left text-[12px] px-1 py-2"
                >
                  {column.render ? column.render(row) : row[column.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      ) : (
        <TableCaption>
          <p className="text-lg text-neutral-500 my-10">
            No se encontraron resultados
          </p>
        </TableCaption>
      )}
    </Table>
  </div>

  );
}