import { Checkbox } from "@/components/ui/checkbox";
import { TableColumn } from "@/components/ui/full-table";
import { formatDate, formatNumber } from "@/dashboard/helpers";
import { useCallback, useMemo, useState } from "react";
import { DescargarFactura, VerFactura } from "../components/factura";
import ActionsPendingRowCell from "@/dashboard/observeds/components/ActionsPendingRowCell";

export default function useTableColumns(
  tableRows: any[],
  currentPage: number,
  pageSize: number,
) {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const handleCheckboxChange = useCallback(
    (code: string) => {
      if (selectedRows.includes(code)) {
        setSelectedRows(selectedRows.filter((row) => row !== code));
      } else {
        setSelectedRows([...selectedRows, code]);
      }
    },
    [selectedRows],
  );

  const handleSelectAllChange = useCallback(
    (selectAll: boolean) => {
    if (!selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(
        tableRows
          .filter((row) => row.xml_cpe_link || row.img_cpe_link)
          .map((row) => row.code)
      );
    }
  }, [selectedRows, tableRows]);
  

const columns: TableColumn[] = useMemo(() => {
  return [
    {
      label: () => {
        const selectableRows = tableRows.filter((row) => row.xml_cpe_link || row.img_cpe_link); 
        return (
          <Checkbox 
            checked={selectedRows.length === selectableRows.length && selectableRows.length > 0}
            onCheckedChange={handleSelectAllChange} 
            disabled={selectableRows.length === 0} 
          />
        );
      },
      key: "select",
      width: "8%",
      render: (row) => (
        <Checkbox
          checked={selectedRows.includes(row.code)}
          onCheckedChange={() => handleCheckboxChange(row.code)}
          disabled={row.img_cpe_link === "" && row.xml_cpe_link === ""}
        />
      ),
    },
    {
      label: "Fecha de emisión",
      width: "32%",
      key: "issue_date",
      render: ({ issue_date }) => `${formatDate(issue_date)}`,
    },
    {
      label: "Tipo comp.",
      width: "15%",
      key: "type_code",
      render: ({ type_code }) => `${type_code}`,
    },
    {
      label: "Serie - Número",
      width: "32%",
      key: "serie",
      render: ({ serie, number }) => `${serie} - ${number}`,
    },
    {
      label: "Nombre/Razón social del proveedor",
      width: "42%",
      key: "supplier_business_name",
    },
    {
      label: "Total IGV",
      width: "34%",
      key: "igv_amount",
      render: ({ igv_amount }) => `s/ ${formatNumber(igv_amount)}`,
    },
    {
      label: "Importe total",
      width: "34%",
      key: "taxable_amount",
      render: ({ taxable_amount }) => `s/ ${formatNumber(taxable_amount)}`,
    },
    {
      label: "Representación",
      width: "35%",
      textAlign: "center",
      key: "file",
      render: ({ img_cpe_link }) => <VerFactura url={img_cpe_link} />,
    },
    {
      label: "Descargas",
      width: "65%",
      textAlign: "center",
      key: "downloads",
      colSpan: 2,
      render: (row) => (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'center', paddingLeft: '30px' }}>
          <DescargarFactura url={row.img_cpe_link} type="jpeg" />
          <DescargarFactura url={row.xml_cpe_link} type="xml" />
        </div>
      ),
    },
    {
      label: "Acciones",
      key: "actions",
      width: "34%",
      render: (cpe) => (
        <ActionsPendingRowCell 
          showButtons={false} 
          cpe={cpe} 
          currentPage={currentPage} 
          pageSize={pageSize} 
          setSelectedRows={(rows: Cpes[]) => setSelectedRows(rows.map(row => row.code))} />
      ),
    },
  ];
}, [selectedRows, tableRows]);


  return {
    columns,
    selectedRows,
    setSelectedRows,
    handleSelectAllChange,
  };
}
