import { Cell, Pie, PieChart } from "recharts";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Card from '../shared/CardInfo.tsx';

const RADIAN = Math.PI / 180;

interface DataGraphic {
    name: string;
    value: number;
    totalValue: number;
    color: string;
    amount?: string;
}

interface GraphicCardProps {
    title: string;
    data: DataGraphic[];
    history_total_amount: HistoryTotalAmount;
    showTitleIcon: boolean;
    iconTitle: string;
    direction: "row" | "column",
    cssClassWrapper?: string;
    textCenterUpper?: string;
    textCenterLower?: string;
    chartType: "pie" | "areaPurchase" | "areaSales";
    children?: React.ReactNode;
}
const CardInfo: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return <div>{children}</div>;
};

function makeid(length: number) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;

    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

function GraphicCard({
    title,
    data,
    showTitleIcon = false,
    iconTitle = '',
    direction = "row",
    cssClassWrapper = '',
    textCenterUpper,
    textCenterLower,
    chartType,
    children,
    history_total_amount,
}: GraphicCardProps) {
    
    const labelCenter = ({ cx, cy, midAngle, innerRadius, outerRadius, index }: any) => {
        const value = data[index]?.value;

        if (value === 0 && !value) {
            return null; // Si el valor es cero, no renderizar etiqueta
        }

        const radius = innerRadius + (outerRadius - innerRadius) * 0.9;

        // Posición central para el label de 100%
        const centerX = cx + radius * Math.cos(-midAngle * RADIAN);
        const centerY = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <g key={makeid(10)}>
                {textCenterUpper && (
                    <g key={makeid(10)}>
                        <text
                            x={centerX + 10}
                            y={centerY - 5}
                            fill="#000000"
                            textAnchor="center"
                            className="text-sm font-semibold"
                            stroke="none"
                            dominantBaseline="middle">
                            {textCenterUpper}
                        </text>
                    </g>
                )}
                {textCenterLower && (
                    <g key={makeid(10)}>
                        <text
                            x={centerX + 15}
                            y={centerY + 20}
                            fill="#000000"
                            textAnchor="center"
                            stroke="none"
                            className="text-xs"
                            dominantBaseline="middle">
                            {textCenterLower}
                        </text>
                    </g>
                )}
            </g>
        );
    };

    const outerRadius = 85;
    const innerRadius = outerRadius - 25;

    let classWrapper = "";

    if (direction === "column") {
        classWrapper = "flex flex-col gap-1 min-[1300px]:gap-[3.7em] items-center justify-between";
      } else {
        classWrapper = "flex flex-row gap-1 min-[1300px]:gap-[3.7em] items-center justify-between flex-nowrap";
      }
      

    
    const months = [
        'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
        'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dic'
      ];
      
      const generateAreaData = (
        historyData: typeof history_total_amount,
        type: "purchase_total_amount" | "sales_total_amount"
      ) => {
        return Array.isArray(historyData)
          ? historyData
              .map((item, index) => ({
                index,
                name: months[item.number_month - 1] || '',
                total: item[type] || 0,
              }))
              .reverse()
          : [];
      };

      const areaDataPurchase = generateAreaData(history_total_amount, "purchase_total_amount");
      const areaDataSales = generateAreaData(history_total_amount, "sales_total_amount");
      const filteredData = data.filter((item) => item.value > 0);

      return (
        <Card className={`bg-white ${chartType === 'areaPurchase' || chartType === 'areaSales' ? 'w-[35rem] md:w-full' : ''
        }`}>
            <Card.Body>
                <div className="flex items-center gap-3 mb-14">
                    { showTitleIcon && chartType === 'pie' &&
                        <div
                            className="w-14 h-14 flex items-center justify-center rounded-full bg-salmon">
                            <img src={ iconTitle } alt="Icono tarjeta"/>
                        </div>
                    }
                    <h3 className="text-xl font-semibold">{ title }</h3>
                </div>
                <div className={ cssClassWrapper !== '' ? cssClassWrapper : classWrapper }>
                    {chartType === "pie" && (
                         <div id="pie_chart" className={ cssClassWrapper.includes('grid') ? 'col-span-2 w-full' : 'w-full' }>
                            <PieChart key={ makeid(10) } width={ 170 } height={ 170 } className="mx-auto">
                                <Pie
                                    nameKey="name"
                                    data={filteredData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    outerRadius={outerRadius}
                                    innerRadius={innerRadius}
                                    fill="#c0c0c0"
                                    dataKey="value"
                                >
                                    {filteredData.map((item, index) => (
                                        <Cell key={`cell-${item.name}-${item.color}-${index}`} fill={item.color} stroke="none" />
                                    ))}
                                </Pie>

                                { (textCenterUpper || textCenterLower) && (
                                    <Pie
                                        nameKey="key"
                                        id="center_label"
                                        data={[{ name: "monto", value: 100 }]}
                                        dataKey="value"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={innerRadius}
                                        fill="#fff"
                                        labelLine={false}
                                        label={labelCenter}
                                        stroke="none"
                                    />
                                ) }
                            </PieChart>
                         </div>
                    )}
                   {(chartType === "areaPurchase" || chartType === "areaSales") && (
                        <>
                            <div id="area_chart" className={`${cssClassWrapper.includes('grid') ? 'col-span-3' : ''} w-full pr-8 overflow-x-auto`}>
                                <ResponsiveContainer width="100%" height={280}>
                                    <AreaChart 
                                            width={500}
                                            height={280}
                                            data={(chartType === 'areaPurchase' ? areaDataPurchase : areaDataSales)}>
                                        <defs>
                                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#2D60FF" stopOpacity={0.3}/>
                                            <stop offset="95%" stopColor="#2D60FF" stopOpacity={0}/>
                                            </linearGradient>
                                        </defs>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" tickMargin={15} tick={{ fill: '#4682b4', fontSize: 12 }} />
                                        <YAxis width={80} tickMargin={2} tick={{ fill: '#4682b4', fontSize: 12 }} tickFormatter={(value) => `S/ ${value.toLocaleString()}`}/>
                                        <Tooltip formatter={(value) => `S/ ${value.toLocaleString()}`}/>
                                        <Area type="monotone" dataKey="total" stroke="#1814F3" strokeWidth={2} fillOpacity={1} fill="url(#colorUv)" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </>
                    )}

                </div>
                <div id="extra_info" className={ cssClassWrapper.includes('grid') ? 'col-span-3 w-full' : 'w-full' }>
                    { children }
                </div>
            </Card.Body>
        </Card>
    );
}

GraphicCard.CardInfo = CardInfo;

export default GraphicCard;
