import { useDashboardStore } from "@/store/DashboardStore";
import { useAuthStore } from "@/store/AuthStore.tsx";
import { useEffect, useState } from "react";
import PlanTypeAlert from "../PlanTypeAlert.tsx";
import ModalVideoTutorial from "../ModalVideoTutorial.tsx";
import HeaderHome from "./components/HeaderHome.tsx";
import CreateBusinessButton from "./components/CreateBusinessButton.tsx";
import BusinessCard from "./components/BusinessCard.tsx";
import EmptyBusiness from "./components/EmptyBusiness.tsx";
import LoadingText from "./components/LoadingText.tsx";
import StatusDataModal from "./components/modal/StatusDataModal.tsx";
import { isSubscriptionExpire } from "./helpers/index.tsx";
import { useNavigate } from "react-router-dom";

enum SubscriptionPlan {
  FREE = 1,
  PAY = 2,
}

function Dashboard() {
  const navigate = useNavigate();
  const { filters, fetchFiltersBusiness, resetReportsPerceptions, resetReportsObserved, resetDashboardData } = useDashboardStore();
  const { user } = useAuthStore();
  
  const [businessSelected, setBusinessSelected] = useState<BusinessFilter | null>(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isEmptyBusiness, setIsEmptyBusiness] = useState(false);
  const [isShowModalRegisterSuccess, setIsShowModalRegisterSuccess] = useState(false);
  const [searching, setSearching] = useState("");

  const today = new Date().setHours(0,0,0,0);
  const endDate = user.subscription?.end_date;
  const suscription_end_date = endDate ? new Date(endDate).setHours(0,0,0,0) : null;
  const isFreePlan = user.subscription?.plan_type === SubscriptionPlan.FREE;
  const isPayPlan = user.subscription?.plan_type === SubscriptionPlan.PAY;

/**
 * Validaciones para visibilidad de aviso y pre aviso de suscripción
 */

  //si la fecha suscripción es mayor a la fecha actual
  const subscriptionNotFinished = 
  suscription_end_date && suscription_end_date > today;

  //si la fecha de suscripción está a 5 días o menos de hoy
  const subscriptionPreFinished = 
  endDate && isSubscriptionExpire(endDate);

  // si la suscripción ya caducó (fecha suscripcion < hoy)
  const suscriptionPayFinished = 
    isPayPlan && suscription_end_date && suscription_end_date <= today; 

  //suscripción gratuita finalizada
  const suscriptionFreeFinished = 
    isFreePlan && suscription_end_date && suscription_end_date <= today;

  //muestra banner suscripción gratuita finalizada
  const subscriptionFreeBanner = (suscriptionFreeFinished || subscriptionNotFinished);

/**
 * Fin Validaciones para visibilidad de aviso y pre aviso de suscripción
 */

  const planType = isFreePlan
    ? subscriptionNotFinished
      ? "free"
      : "active_trial"
    : isPayPlan
    ? suscriptionPayFinished
      ? "expired_plan"
      : subscriptionPreFinished
      ? "pre_expired_plan"
      : null
    : null;
  
  const businessCardDisabled = 
    suscription_end_date ? today >= suscription_end_date : false;

  const filteredBusinesses = 
    filters?.businesses.filter((business) => 
      business.name.toLowerCase().includes(searching.toLowerCase()));

  useEffect(() => {
    const isFirstLogin = localStorage.getItem("isFirstLogin");

    if (isFirstLogin === "true") {
      setIsShowModalRegisterSuccess(true);
    }
    
    resetReportsObserved();
    resetReportsPerceptions();
    resetDashboardData();

    fetchFiltersBusiness()
    .then((response) => {
      setIsEmptyBusiness(false);
      if (response && response.businesses.length === 0) {
        console.log("No businesses");
        setIsEmptyBusiness(true);
      } 
      else {
        const allInProgressBusiness = response?.businesses.every((business) => business.status === "IN_PROGRESS" );
          if (allInProgressBusiness) {
            navigate('/registro-empresa', { state: { filters: response?.businesses } });
        }
      }
    })
    .catch((error) => {
      if (error.statusText !== "canceled") {
        console.error("Filters couldn't fetch");
      }
    });

}, [fetchFiltersBusiness, resetReportsObserved, resetReportsPerceptions, resetDashboardData]);
  
  return (
    <div className="px-6 py-8">
      {!isEmptyBusiness && <HeaderHome name={user.name} setSearching={setSearching} businessCardDisabled={businessCardDisabled} />}

      {filters.businesses?.length > 0 ? (
        <>
          {(subscriptionFreeBanner || suscriptionPayFinished) && (
            <PlanTypeAlert planType={planType} endDate={endDate} />
          )}

          <div className="grid gap-6 grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 mt-6 mb-16 lg:mb-0">
            {filteredBusinesses
              .sort((a, b) => (a.status > b.status ? -1 : 1))
              .map((business, i) => (
                <BusinessCard
                  key={`card_${i}`}
                  index={i}
                  user={user}
                  business={business}
                  setBusinessSelected={setBusinessSelected}
                  setAlertOpen={setIsAlertOpen}
                  disabled={businessCardDisabled}
                />
              ))}
          </div>

          <div className="block mt-6 md:hidden fixed bottom-0 left-0 h-auto bg-white w-full p-4">
            <CreateBusinessButton disabled={businessCardDisabled} />
          </div>
        </>
      ) : (
        !isEmptyBusiness && <LoadingText />
      )}

      {isEmptyBusiness && <EmptyBusiness />}

      {businessSelected && (
        <StatusDataModal businessSelected={businessSelected} open={isAlertOpen} onClose={() => setIsAlertOpen(false)} />
      )}
      
      {isShowModalRegisterSuccess && (
        <ModalVideoTutorial modalType="registerSuccess" showModal={isShowModalRegisterSuccess} />
      )}
    </div>
  );
}

export default Dashboard;
