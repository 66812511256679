export interface Plan {
    id: number;
    title: string;
    subtitle: string;
    typePlan: string;
    prices: number;
    priceDebAuto?: number,
    label?: React.ReactNode,
    features: string[];
    recommended?: boolean;
    link?: string;
}

export const plansPrices: Plan[] = [
    {
        id: 3,
        title: "Negocio independiente",
        subtitle: "5 empresas",
        typePlan: 'Mensual',
        prices: 59,
        priceDebAuto: 44,
        label: (<>en tu <br /> primer mes</>
        ),
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Comprobantes de SIRE con items."
        ],
        recommended: false,
        link: 'https://subscriptions.culqi.com/onboarding?id=5da81b15-c8b2-4b76-ae93-c0b362e89666',
    },
    {
        id: 6,
        title: "Negocio independiente",
        subtitle: "5 empresas",
        typePlan: 'Trimestral',
        prices: 159.30,
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Comprobantes de SIRE con items."
        ],
        recommended: false,
    },
    {
        id: 9,
        title: "Negocio independiente",
        subtitle: "5 empresas",
        typePlan: 'Anual',
        prices: 589.98,
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Comprobantes de SIRE con items."
        ],
        recommended: false,
    },
    {
        id: 4,
        title: "Negocio pequeño",
        subtitle: "15 empresas",
        typePlan: 'Mensual',
        prices: 118,
        priceDebAuto: 88,
        label: (<>en tu <br /> primer mes</>),
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Comprobantes de SIRE con items."
        ],
        recommended: true,
        link: 'https://subscriptions.culqi.com/onboarding?id=c8ec6219-253d-47f2-a514-44a501ba0f34',
    },
    {
        id: 7,
        title: "Negocio pequeño",
        subtitle: "15 empresas",
        typePlan: 'Trimestral',
        prices: 318.60,
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Comprobantes de SIRE con items."
        ],
        recommended: true,
    },
    {
        id: 10,
        title: "Negocio pequeño",
        subtitle: "15 empresas",
        typePlan: 'Anual',
        prices: 1180,
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Comprobantes de SIRE con items."
        ],
        recommended: true,
    },
    {
        id: 5,
        title: "Negocio emergente",
        subtitle: "50 empresas",
        typePlan: 'Mensual',
        prices: 177,
        priceDebAuto: 132,
        label: (<>en tu <br /> primer mes</>),
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Comprobantes de SIRE con items."
        ],
        recommended: false,
        link: 'https://subscriptions.culqi.com/onboarding?id=835f3e5b-0aed-4e61-8dc6-b7db3c65fb3d',
    },
    {
        id: 8,
        title: "Negocio emergente",
        subtitle: "50 empresas",
        typePlan: 'Trimestral',
        prices: 477.90,
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Comprobantes de SIRE con items."
        ],
        recommended: false,
    },
    {
        id: 11,
        title: "Negocio emergente",
        subtitle: "50 empresas",
        typePlan: 'Anual',
        prices: 1770,
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Comprobantes de SIRE con items."
        ],
        recommended: false,
    },
];