import CopyAmountButton from "@/dashboard/reportPDT/Button";
import Card from "./CardInfo";
import arrowRightBlue from "/assets/ic_arrow_right_blue.svg?url";
import classNames from "classnames";

type UndefinedCallback = () => void;

interface CardSmallProps {
    primaryNumber: number;
    secundaryNumber?: string;
    caption?: string;
    linkTo?: UndefinedCallback;
    reverse?: boolean;
}

const CardSmall = ({ primaryNumber, secundaryNumber, caption, linkTo, reverse }: CardSmallProps) => {
    let baseClass = "flex flex-col";
    let baseClassCaption = "text-sm";

    if (reverse) {
        baseClass = classNames(baseClass, "flex-col-reverse");
        baseClassCaption = classNames("text[#15161C] font-semibold text-lg mb-6", "mt-0", "mb-2");
    } else {
        baseClass = classNames(baseClass, "flex-col");
        baseClassCaption = classNames(baseClassCaption, "mt-2");
    }

    return (
        <Card className="h-full">
            <Card.Body>
                <div>
                    <div className={baseClass}>
                        <div>
                            <CopyAmountButton amount={primaryNumber} />
                            <p className="text-[#5D6075] f-secondary text-sm">Monto de IGV</p>
                            { secundaryNumber && (
                                <span className="text-base font-normal max-sm:text-sm">({ secundaryNumber })</span>
                            ) }
                        </div>
                        { caption && <div className={ classNames('text-base caption font-medium', baseClassCaption)}>{caption}</div>}
                    </div>
                    {linkTo && (
                        <div className="flex mt-6 items-center gap-1 text-sm text-primary-blue-2 font-bold f-secondary cursor-pointer" onClick={linkTo}>
                            Ver detalle <img src={arrowRightBlue} alt="->" />
                        </div>
                    )}
                </div>
            </Card.Body>
        </Card>
    );
};

export default CardSmall;
