import NavBar from "@/shared/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { Key, useEffect, useState } from "react";
import { useAuthStore } from "@/store/AuthStore";
import icProgressCircular from "/assets/ic_progress_circular.svg?url";

const AllInProgressBusiness = () => {
  const location = useLocation();
  const [filters, setFilters] = useState(location.state?.filters || null);

  useEffect(() => {
    if (!filters) {
      const filters = localStorage.getItem("filters");
      if (filters) {
        setFilters(JSON.parse(filters));
      }
    }
  }, [filters]);
  
  const { user } = useAuthStore();
  const { subscription } = user;
  const endDate = subscription?.end_date;
  
  const navigate = useNavigate();
  const userHasFreeSubscription = user.subscription?.plan_type === 1;
  const listBusinesses = filters.map((list: any) => list.name)

  function calculateDays(endDate: string): number {
    const today = new Date();
    const end = new Date(endDate);
    const differenceInMilliseconds = end.getTime() - today.getTime();
    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    return Math.max(differenceInDays, 0);
}

  const daysRemaining = endDate ? calculateDays(endDate) : 0;
  
  const setValuePlanType = () => {
    navigate("/planes-y-precios");
  }

  return (
    <main className="min-h-screen bg-white w-full">
      {userHasFreeSubscription && (
        <>
          <div className="flex items-center justify-center space-x-2 bg-[#071A40] text-[#fff] py-3">
            <p className="text-xs">
              Te {daysRemaining > 1 ? 'quedan' : 'queda'} {daysRemaining} {daysRemaining > 1 ? 'días' : 'día'} de prueba gratis 🎁
              <a
                onClick={() => setValuePlanType()} 
                className="cursor-pointer text-blue-500 font-semibold underline">
                Adquiere tu plan aquí
              </a>
            </p>
          </div>
        </>
      )}

      <NavBar customCssStyle="border-b" />
      
      <div className="flex justify-center mt-12">
        <div className="w-2/5 text-center">
          <div className="flex justify-center">
            <img src={icProgressCircular} className="w-20" alt="Icono de progreso" />
          </div>
          <h2 className="text-base font-semibold mt-6">
            Estamos procesando los datos de{" "}
            {listBusinesses?.length === 1 ? "tu empresa." : "tus empresas."}
          </h2>
          <p className="text-sm mt-4">
            En un máximo de <b>24 horas</b> podrás ver el detalle de los comprobantes de{" "}
            {listBusinesses?.length === 1 ? "la siguiente empresa:" : "las siguientes empresas:"}
          </p>
          {listBusinesses && (
            <ul className="list-disc list-inside mt-4">
              {filters
                .map((listBusinesses: { name: string | null | undefined }, index: Key | null | undefined) => (
                  <li key={index} className="list-none">{listBusinesses.name}</li>
                ))}
            </ul>
          )}

          <div className="rounded-lg px-6 py-5 my-8 mx-auto banner bg-[#E0F3FF]">
            <p className="text-sm mb-3 f-secondary font-normal">
              Mientras esperas, explora todas las funciones de Contapro 🚀
            </p>
            <button
              className="rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2 text-white bg-gray-800 border border-stone-950"
              type="button"
              onClick={() => window.open('https://youtube.com/@somoscontapro?si=pL7y-STIlTKL7n1D', '_blank')}
            >
              Explora tutoriales
            </button>
            {
              daysRemaining !== 0 && (
                <div className="mt-2">
                  <a href="/registrar-empresa" className="text-sm text-blue-500 font-semibold">
                    O registra más empresas
                  </a>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </main>
  );
};

export default AllInProgressBusiness;